import React, { useEffect, useState } from 'react';
import { FaSave, FaFilePdf, FaMarkdown, FaListOl, FaUserPlus, FaArrowLeft } from 'react-icons/fa';
import ExportButtons from './ExportButtons';
import InviteCollaborator from './InviteCollaborator';
import { Form } from 'react-bootstrap';

const Sidebar = ({ isOpen, toggleSidebar, documentId, title, content, authToken, generateTOC, handleSave, autoSaveEnabled, handleAutoSaveChange }) => {
  const [buttonPosition, setButtonPosition] = useState({ top: 'calc(50% - 20px)' });

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (window.innerWidth - event.clientX <= 100) {
        setButtonPosition({ top: `${event.clientY - 20}px` });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
      <button className="sidebar-toggle-btn" onClick={toggleSidebar} style={buttonPosition}>
        <FaArrowLeft />
      </button>
      <div className="button-group-container">
        <button onClick={() => handleSave(false)} className="save-btn">
          <FaSave /> Save
        </button>
        <Form.Check
          type="checkbox"
          label="Enable Auto-Save"
          checked={autoSaveEnabled}
          onChange={handleAutoSaveChange}
          className="mb-3"
        />
        <ExportButtons title={title} content={content} generateTOC={generateTOC} />
        {documentId && (
          <InviteCollaborator documentId={documentId} />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
