import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth';
import logo from '../assets/logo.png'; // Assurez-vous que le chemin est correct
import './Header.css'; // Importez le fichier CSS pour le style personnalisé

const Header = () => {
  const [user] = useAuthState(auth);

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
      <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
        <img
          src={logo}
          alt="HarmonizeText"
          className="logo"
        />
        <span className="ml-3 brand-text">HarmonizeText</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/" className="nav-link">Home</Nav.Link>
          {user ? (
            <>
              <Nav.Link as={Link} to="/dashboard" className="nav-link">Dashboard</Nav.Link>
              <Nav.Link as={Link} to="/documents" className="nav-link">Documents</Nav.Link>
              <Button variant="outline-light" onClick={handleLogout} className="nav-button">Logout</Button>
            </>
          ) : (
            <>
              <Nav.Link as={Link} to="/login" className="nav-link">Login</Nav.Link>
              <Nav.Link as={Link} to="/signup" className="nav-link">Sign Up</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
