import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ListGroup, Tab, Nav, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import './DocumentList.css';

const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [sharedDocuments, setSharedDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [loadingSharedDocuments, setLoadingSharedDocuments] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/documents`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setDocuments(response.data);
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      } finally {
        setLoadingDocuments(false);
      }
    };

    const fetchSharedDocuments = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/documents-shared`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSharedDocuments(response.data);
        }
      } catch (error) {
        console.error('Error fetching shared documents:', error);
      } finally {
        setLoadingSharedDocuments(false);
      }
    };

    fetchDocuments();
    fetchSharedDocuments();
  }, [auth]);

  const handleDeleteClick = (document) => {
    setSelectedDocument(document);
    setShowConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const user = auth.currentUser;
      if (user && selectedDocument) {
        const token = await user.getIdToken();
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/documents/${selectedDocument.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDocuments(documents.filter(doc => doc.id !== selectedDocument.id));
        setShowConfirm(false);
        setSelectedDocument(null);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2>Your Documents</h2>
          <Tab.Container defaultActiveKey="own">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="own">Own Documents</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="shared">Shared Documents</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="own">
                {loadingDocuments ? (
                  <div className="spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <ListGroup>
                    {documents.map((doc) => (
                      <ListGroup.Item key={doc.id}>
                        <Link to={`/documents/${doc.id}`}>{doc.title}</Link>
                        <Button variant="danger" className="float-right" onClick={() => handleDeleteClick(doc)}>Delete</Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
                <Button variant="primary" as={Link} to="/create-document" className="mt-3">
                  Create New Document
                </Button>
              </Tab.Pane>
              <Tab.Pane eventKey="shared">
                {loadingSharedDocuments ? (
                  <div className="spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <ListGroup>
                    {sharedDocuments.map((doc) => (
                      <ListGroup.Item key={doc.id}>
                        <Link to={`/documents/${doc.id}`}>{doc.title}</Link>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>

      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{selectedDocument?.title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DocumentList;
