// /editorComponents/TitleInput.js
import React from 'react';
import { Form } from 'react-bootstrap';

const TitleInput = ({ title, setTitle }) => (
  <Form.Group controlId="formTitle">
    <Form.Label>Title</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter title"
      name="title"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
    />
  </Form.Group>
);

export default TitleInput;
