import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaLock, FaUsers, FaRocket, FaPenFancy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase-config';
import './Home.css'; // Importez le fichier CSS pour le style personnalisé

const Home = () => {
  const [user] = useAuthState(auth);

  return (
    <Container className="home-container my-5">
      <Row className="justify-content-md-center mb-5">
        <Col md={8} className="text-center">
          <h1 className="display-4">Welcome to Harmonize Text</h1>
          <p className="lead">Create homogeneous and aesthetic documents quickly.</p>
          {user ? (
            <p>Let's start using Harmonize Text!</p>
          ) : (
            <p>Please sign in or sign up to continue.</p>
          )}
          <div className="d-flex justify-content-center align-items-center">
            {user ? (
              <Button 
                as={Link} 
                to="/documents" 
                variant="primary" 
                size="lg" 
                className="mx-2 btn-documents"
              >
                Go to your documents
              </Button>
            ) : (
              <>
                <Button 
                  as={Link} 
                  to="/login" 
                  variant="primary" 
                  size="lg" 
                  className="mx-2 btn-login"
                >
                  Login
                </Button>
                <Button 
                  as={Link} 
                  to="/signup" 
                  variant="success" 
                  size="lg" 
                  className="mx-2 btn-signup"
                >
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row className="text-center">
        <Col md={4} className="mb-4">
          <Card className="h-100 feature-card">
            <Card.Body>
              <FaPenFancy size={50} className="mb-3 text-primary"/>
              <Card.Title>Create Beautiful Documents</Card.Title>
              <Card.Text>
                Harmonize Text allows you to create aesthetically pleasing documents with ease.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="h-100 feature-card">
            <Card.Body>
              <FaLock size={50} className="mb-3 text-primary"/>
              <Card.Title>Secure and Encrypted</Card.Title>
              <Card.Text>
                All your data is encrypted and secure with Harmonize Text.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="h-100 feature-card">
            <Card.Body>
              <FaUsers size={50} className="mb-3 text-primary"/>
              <Card.Title>Real-Time Collaboration</Card.Title>
              <Card.Text>
                Edit documents in real-time with multiple collaborators.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={8} className="text-center">
          <Card className="bg-light mt-5 beta-card">
            <Card.Body>
              <FaRocket size={50} className="mb-3 text-primary beta-icon"/>
              <Card.Title>Beta Version - Free!</Card.Title>
              <Card.Text>
                Harmonize Text is currently in beta and available for free.
              </Card.Text>
              <div className="d-flex justify-content-center align-items-center">
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
