import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const Dashboard = () => {
  const [invitations, setInvitations] = useState([]);
  const auth = getAuth();

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/invitations`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          
          setInvitations(response.data);
        }
      } catch (error) {
        console.error('Error fetching invitations:', error);
      }
    };

    fetchInvitations();
  }, [auth]);

  const handleAccept = async (id) => {
    try {
      const user = auth.currentUser;
      const token = await user.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/documents/${id}/accept`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInvitations(invitations.filter((invitation) => invitation.id !== id));
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  const handleDecline = async (id) => {
    try {
      const user = auth.currentUser;
      const token = await user.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/documents/${id}/decline`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInvitations(invitations.filter((invitation) => invitation.id !== id));
    } catch (error) {
      console.error('Error declining invitation:', error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2>Dashboard</h2>
          <h4>Invitations</h4>
          <ListGroup>
            {invitations.map((invitation) => (
              <ListGroup.Item key={invitation.id}>
                <span>{invitation.title}</span>
                <span className="ms-2 text-muted">Invited by: {invitation.inviterEmail}</span>
                <Button variant="success" onClick={() => handleAccept(invitation.id)} className="ms-2">
                  Accept
                </Button>
                <Button variant="danger" onClick={() => handleDecline(invitation.id)} className="ms-2">
                  Decline
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
