import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';  // Ajoutez cette ligne

const firebaseConfig = {
  apiKey: "AIzaSyChiiELkW-sDAYcctw5O9D0ScjP9X177h8",
  authDomain: "fasetreport.firebaseapp.com",
  projectId: "fasetreport",
  storageBucket: "fasetreport.appspot.com",
  messagingSenderId: "251688621585",
  appId: "1:251688621585:web:d2c7483ab789e80b0943b0",
  measurementId: "G-GCTECTBMMR"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);  // Ajoutez cette ligne

export { auth, db, analytics, storage };  // Ajoutez storage à l'export