const Toolbar = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'header': '4' }, { 'header': '5' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'code-block'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  },
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function (range, context) {
          if (context.offset === 0) {
            this.quill.format('indent', '+1', 'user');
          } else {
            this.quill.insertText(range.index, '\t', 'user');
            this.quill.setSelection(range.index + 1, 'silent');
          }
          return false;
        },
      },
      enter: {
        key: 13,
        handler: function (range, context) {
          const currentLine = this.quill.getLine(range.index)[0];
          const indentation = currentLine.domNode.textContent.match(/^\s*/)[0];
          this.quill.insertText(range.index, '\n' + indentation, 'user');
          this.quill.setSelection(range.index + indentation.length + 1, 'silent');
          return false;
        },
      },
      bold: {
        key: 'b',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'bold', !format.bold);
          } else {
            this.quill.format('bold', !context.format.bold);
          }
        },
      },
      italic: {
        key: 'i',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'italic', !format.italic);
          } else {
            this.quill.format('italic', !context.format.italic);
          }
        },
      },
      underline: {
        key: 'u',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'underline', !format.underline);
          } else {
            this.quill.format('underline', !context.format.underline);
          }
        },
      },
      header1: {
        key: '1',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'header', format.header === 1 ? false : 1);
          } else {
            this.quill.format('header', context.format.header === 1 ? false : 1);
          }
        },
      },
      header2: {
        key: '2',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'header', format.header === 2 ? false : 2);
          } else {
            this.quill.format('header', context.format.header === 2 ? false : 2);
          }
        },
      },
      header3: {
        key: '3',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'header', format.header === 3 ? false : 3);
          } else {
            this.quill.format('header', context.format.header === 3 ? false : 3);
          }
        },
      },
      header4: {
        key: '4',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'header', format.header === 4 ? false : 4);
          } else {
            this.quill.format('header', context.format.header === 4 ? false : 4);
          }
        },
      },
      header5: {
        key: '5',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'header', format.header === 5 ? false : 5);
          } else {
            this.quill.format('header', context.format.header === 5 ? false : 5);
          }
        },
      },
      blockquote: {
        key: 'q',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'blockquote', !format.blockquote);
          } else {
            this.quill.format('blockquote', !context.format.blockquote);
          }
        },
      },
      bulletList: {
        key: 'l',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'list', format.list === 'bullet' ? false : 'bullet');
          } else {
            this.quill.format('list', context.format.list === 'bullet' ? false : 'bullet');
          }
        },
      },
      orderedList: {
        key: 'o',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'list', format.list === 'ordered' ? false : 'ordered');
          } else {
            this.quill.format('list', context.format.list === 'ordered' ? false : 'ordered');
          }
        },
      },
      codeBlock: {
        key: 'k',
        shortKey: true,
        handler: function (range, context) {
          if (range.length === 0) {
            const [line, offset] = this.quill.getLine(range.index);
            const format = this.quill.getFormat(range.index);
            const lineStartIndex = this.quill.getIndex(line);
            this.quill.formatText(lineStartIndex, line.length(), 'code-block', !format['code-block']);
          } else {
            this.quill.format('code-block', !context.format['code-block']);
          }
        },
      },
      selectAllInCodeBlock: {
        key: 'a',
        shortKey: true,
        handler: function (range, context) {
          const [line, offset] = this.quill.getLine(range.index);
          const format = this.quill.getFormat(range.index);
          if (format['code-block']) {
            const lineStartIndex = this.quill.getIndex(line);
            const lineLength = line.length();
            this.quill.setSelection(lineStartIndex, lineLength, 'user');
          } else {
            return true; // Laissez le comportement par défaut de Ctrl+A si ce n'est pas un bloc de code
          }
          return false;
        },
      },
    }
  }
};

export default Toolbar;
