import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import Toolbar from './Toolbar';
import './typora-theme.css';
import { io } from 'socket.io-client';
import { storage } from '../../firebase-config';  // Assurez-vous que le chemin est correct
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ContentEditor = ({ content, setContent, quillRef, documentId }) => {
  const editorContainerRef = useRef(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '/';
    const newSocket = io(apiUrl);
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit('joinDocument', documentId);

      socket.on('receiveChanges', (delta) => {
        quillRef.current.getEditor().updateContents(delta);
      });
    }
  }, [socket, documentId, quillRef]);

  const handleChange = useCallback((content, delta, source) => {
    if (source === 'user') {
      socket.emit('sendChanges', documentId, delta);
    }
    setContent(content);
  }, [socket, documentId, setContent]);

  const uploadImageToFirebase = async (file) => {
    const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handlePaste = useCallback(async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    const quill = quillRef.current.getEditor();
    let textPasted = false;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        event.preventDefault();
        const file = items[i].getAsFile();
        try {
          const imageUrl = await uploadImageToFirebase(file);
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', imageUrl, 'user');
          quill.formatLine(range.index, 1, 'align', 'center');
          quill.setSelection(range.index + 1);
        } catch (error) {
          console.error('Error handling pasted image:', error);
        }
      } else if (items[i].type.indexOf('text') !== -1) {
        textPasted = true;
      }
    }

    if (textPasted) {
      setTimeout(() => {
        const range = quill.getSelection();
        quill.scrollIntoView(range);
      }, 0);
    }
  }, [quillRef]);

  useEffect(() => {
    if (editorContainerRef.current) {
      const blocks = editorContainerRef.current.querySelectorAll('pre code');
      blocks.forEach((block) => {
        hljs.highlightBlock(block);
      });
    }
  }, [content]);

  useEffect(() => {
    const quill = quillRef.current.getEditor();

    const handleScroll = () => {
      const cursorPosition = quill.getSelection();
      if (cursorPosition) {
        const bounds = quill.getBounds(cursorPosition.index);
        const cursorY = bounds.bottom;
        const windowHeight = window.innerHeight;
        const threshold = windowHeight - 100;

        if (cursorY > threshold) {
          window.scrollBy(0, cursorY - threshold);
        }
      }
    };

    quill.on('selection-change', handleScroll);

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'c') {
        if (event.shiftKey) {
          const range = quill.getSelection();
          if (range && !range.isCollapsed) {
            const text = quill.getText(range.index, range.length);
            navigator.clipboard.writeText(text).then(() => {
              console.log('Text copied without formatting');
            });
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      quill.off('selection-change', handleScroll);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [quillRef]);

  return (
    <div ref={editorContainerRef} style={{ width: '80%', margin: '0 auto' }} className="typora-content">
      <ReactQuill
        ref={quillRef}
        value={content}
        onChange={handleChange}
        modules={Toolbar}
        formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'list', 'bullet', 'indent', 'link', 'image', 'align']}
        placeholder="Enter some text..."
        onPaste={handlePaste}
      />
    </div>
  );
};

export default ContentEditor;