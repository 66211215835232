import React from 'react';
import { saveAs } from 'file-saver';
import { FaFilePdf, FaMarkdown, FaListOl } from 'react-icons/fa';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import showdown from 'showdown';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ExportButtons = ({ title, content, generateTOC }) => {
  const converter = new showdown.Converter();
  const htmlContent = converter.makeHtml(content);

  const handleExportMarkdown = () => {
    const blob = new Blob([content], { type: 'text/markdown;charset=utf-8' });
    saveAs(blob, `${title || 'document'}.md`);
  };

  const handleExportPDF = async () => {
    const docDefinition = {
      content: [],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        subheader: {
          fontSize: 18,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        quote: {
          italics: true,
          alignment: 'center',
          color: '#666',
          margin: [0, 10, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const convertHtmlToPdfMakeContent = async (element, parent) => {
      switch (element.nodeName) {
        case 'H1':
          parent.push({
            text: element.innerText,
            style: 'header',
          });
          break;
        case 'H2':
          parent.push({
            text: element.innerText,
            style: 'subheader',
          });
          break;
        case 'BLOCKQUOTE':
          parent.push({
            text: element.innerText,
            style: 'quote',
          });
          break;
        case 'P':
          parent.push({
            text: element.innerText,
            margin: [0, 5, 0, 5],
          });
          break;
        case 'IMG':
          const imageUrl = element.src;
          const imageData = await fetch(imageUrl)
            .then(res => res.blob())
            .then(blob => new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            }));
          parent.push({
            image: imageData,
            width: 500,
          });
          break;
        case 'UL':
          const ulContent = [];
          Array.from(element.children).forEach((li) => {
            convertHtmlToPdfMakeContent(li, ulContent);
          });
          parent.push({
            ul: ulContent,
            margin: [0, 5, 0, 5],
          });
          break;
        case 'OL':
          const olContent = [];
          Array.from(element.children).forEach((li) => {
            convertHtmlToPdfMakeContent(li, olContent);
          });
          parent.push({
            ol: olContent,
            margin: [0, 5, 0, 5],
          });
          break;
        case 'LI':
          parent.push(element.innerText);
          break;
        case 'TABLE':
          const tableBody = [];
          Array.from(element.children).forEach((tr) => {
            const row = [];
            Array.from(tr.children).forEach((cell) => {
              row.push({
                text: cell.innerText,
                style: 'tableHeader',
              });
            });
            tableBody.push(row);
          });
          parent.push({
            table: {
              body: tableBody,
            },
            margin: [0, 5, 0, 5],
          });
          break;
        default:
          if (element.childNodes.length) {
            Array.from(element.childNodes).forEach((child) =>
              convertHtmlToPdfMakeContent(child, parent)
            );
          }
          break;
      }
    };

    await convertHtmlToPdfMakeContent(doc.body, docDefinition.content);

    pdfMake.createPdf(docDefinition).download(`${title || 'document'}.pdf`);
  };

  return (
    <div className="export-button-group">
      <button onClick={generateTOC} className="export-btn">
        <FaListOl /> Generate TOC
      </button>
      <button onClick={handleExportMarkdown} className="export-btn">
        <FaMarkdown /> Export as Markdown
      </button>
      <button onClick={handleExportPDF} className="export-btn">
        <FaFilePdf /> Export as PDF
      </button>
    </div>
  );
};

export default ExportButtons;
