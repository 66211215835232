import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Form, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import TitleInput from './editorComponents/TitleInput';
import ContentEditor from './editorComponents/ContentEditor';
import Sidebar from './editorComponents/Sidebar';
import io from 'socket.io-client';
import './DocumentEditor.css';

const DocumentEditor = () => {
  const { id: initialId } = useParams();
  const [documentId, setDocumentId] = useState(initialId || '');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authToken, setAuthToken] = useState('');
  const [lastModified, setLastModified] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const auth = getAuth();
  const firestore = getFirestore();
  const quillRef = useRef(null);
  const socketRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSidebarOpen && !event.target.closest('.sidebar')) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '/';
    socketRef.current = io(apiUrl, {
      query: {
        userId: auth.currentUser.uid,
      },
    });
    if (documentId) {
      socketRef.current.emit('joinDocument', documentId);
    }

    socketRef.current.on('documentEdit', (newContent) => {
      setContent(newContent);
    });

    return () => {
      if (documentId) {
        socketRef.current.emit('leaveDocument', documentId);
      }
      socketRef.current.disconnect();
    };
  }, [documentId, auth]);

  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (user && documentId) {
          const token = await user.getIdToken();
          setAuthToken(token);
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/documents/${documentId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const fetchedDocument = response.data;
          setTitle(fetchedDocument.title || '');
          setContent(fetchedDocument.content || '');
        } else {
          setTitle('');
          setContent('');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setError('Error fetching document');
        setTitle('');
        setContent('');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentId, auth]);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userPrefDoc = doc(firestore, `userPreferences/${user.uid}`);
          const userPrefSnap = await getDoc(userPrefDoc);
          if (userPrefSnap.exists()) {
            setAutoSaveEnabled(userPrefSnap.data().autoSaveEnabled);
          }
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    fetchUserPreferences();
  }, [auth, firestore]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoSaveEnabled && lastModified) {
        handleSave(true);
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [lastModified, autoSaveEnabled]);

  const handleContentChange = (newContent) => {
    setContent(newContent);
    setLastModified(new Date());
    if (documentId) {
      socketRef.current.emit('documentEdit', { documentId, content: newContent });
    }
  };

  const handleSave = async (isAutoSave = false) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const updatedDocument = { title, content };

        if (documentId) {
          await axios.put(`${process.env.REACT_APP_API_BASE_URL}/documents/${documentId}`, updatedDocument, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/documents`, updatedDocument, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const newDocumentId = response.data.id;
          setDocumentId(newDocumentId);
          window.history.replaceState(null, '', `/documents/${newDocumentId}`);
        }

        setToastMessage(isAutoSave ? 'Document auto-saved!' : 'Document saved successfully!');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      }
    } catch (error) {
      console.error('Error saving document:', error);
      setToastMessage('Error saving document');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const handleKeyDown = useCallback((event) => {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      handleSave();
    }
  }, [handleSave]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleAutoSaveChange = async (e) => {
    const newValue = e.target.checked;
    setAutoSaveEnabled(newValue);
    try {
      const user = auth.currentUser;
      if (user) {
        const userPrefDoc = doc(firestore, `userPreferences/${user.uid}`);
        await setDoc(userPrefDoc, { autoSaveEnabled: newValue });
      }
    } catch (error) {
      console.error('Error saving user preferences:', error);
    }
  };

  const generateTOC = () => {
    if (!quillRef.current) return;
  
    const editor = quillRef.current.getEditor();
    const contents = editor.getContents();
  
    const headers = [];
    let headerIndex = 0;
  
    contents.ops.forEach((op, index) => {
      if (op.attributes && op.attributes.header) {
        const level = op.attributes.header;
        const title = op.insert.trim();
        const id = `header-${headerIndex}`;
        headers.push({ level, title, id });
        
        // Insert an anchor before the header
        editor.insertText(index, '\n', { 'anchor-id': id }, 'silent');
        headerIndex++;
      }
    });
  
    if (headers.length === 0) {
      console.log("No headers found in the document.");
      return;
    }
  
    const tocEntries = headers.map(header => {
      const indent = '  '.repeat(header.level - 1);
      return `${indent}- [${header.title}](#${header.id})`;
    });
  
    const toc = `# Table of Contents\n${tocEntries.join('\n')}\n\n`;
  
    // Insert the TOC at the beginning of the document
    editor.insertText(0, toc, 'user');
    
    // Update the content state
    handleContentChange(editor.root.innerHTML);
  };
  return (
    <Container fluid className="editor-container">
      <div className={`editor-content ${isSidebarOpen ? 'with-sidebar' : ''}`}>
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="form-title">
              <TitleInput title={title} setTitle={setTitle} />
            </div>
            <div className="form-content">
              <Form.Group controlId="formContent">
                <Form.Label>Content</Form.Label>
                <ContentEditor
                  content={content}
                  setContent={handleContentChange}
                  quillRef={quillRef}
                />
              </Form.Group>
            </div>
          </>
        )}
        <ToastContainer position="bottom-end" className="p-3">
          <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={toastMessage.includes('Error') ? 'danger' : 'success'}>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
      <Sidebar 
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        documentId={documentId} 
        title={title} 
        content={content} 
        authToken={authToken} 
        generateTOC={generateTOC} 
        handleSave={handleSave} 
        autoSaveEnabled={autoSaveEnabled}
        handleAutoSaveChange={handleAutoSaveChange}
      />
      <div className={`overlay ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
    </Container>
  );
};

export default DocumentEditor;
