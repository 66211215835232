import React, { useState, useEffect } from 'react';
import { Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { FaUserPlus } from 'react-icons/fa';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

const InviteCollaborator = ({ documentId }) => {
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [variant, setVariant] = useState('success');
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const auth = getAuth();
  const firestore = getFirestore();

  const handleInvite = async () => {
    try {
      const user = auth.currentUser;
      const token = await user.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/documents/${documentId}/invite`, { email }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setToastMessage('Invitation sent successfully');
      setVariant('success');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (error) {
      console.error('Error sending invitation:', error);
      setToastMessage('Error sending invitation');
      setVariant('danger');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  useEffect(() => {
    const docRef = doc(firestore, 'documents', documentId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setPendingInvitations(data.invitations || []);
        setCollaborators(data.collaborators || []);
      }
    });

    return () => unsubscribe();
  }, [firestore, documentId]);

  return (
    <div className="invite-collaborator">
      <Form.Group controlId="inviteCollaboratorEmail">
        <Form.Label>Invite Collaborator</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-2"
        />
      </Form.Group>
      <Button variant="primary" onClick={handleInvite} className="d-flex align-items-center">
        <FaUserPlus className="mr-1" /> Invite
      </Button>

      <h5 className="mt-4">Pending Invitations</h5>
      <ul>
        {pendingInvitations.map((invitation, index) => (
          <li key={index}>{invitation.email}</li>
        ))}
      </ul>

      <h5 className="mt-4">Collaborators</h5>
      <ul>
        {collaborators.map((collaborator, index) => (
          <li key={index}>{collaborator} - <span className={`status ${collaborator.online ? 'online' : 'offline'}`}>{collaborator.online ? 'Online' : 'Offline'}</span></li>
        ))}
      </ul>

      <ToastContainer position="bottom-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={variant}>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default InviteCollaborator;
